<template>
  <div>
    <apexchart
      width="100%"
      height="300"
      type="area"
      :options="chartOptions"
      :series="[
        {
          name: 'Pago',
          data: formattedData,
        },
      ]"
    ></apexchart>
  </div>
</template>

<script>
import { formatCurrency, formatFloat } from "../../helpers/formatters";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "PaymentSeriesChart",
  props: ["data"],
  data() {
    return {
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Ingresos",
          align: "left",
          style: {
            color: this.$vuetify.theme.currentTheme.secondary, // Change this to your desired color
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false, // Hides the x-axis labels
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return formatCurrency(val);
            },
            style: {
              colors: [this.$vuetify.theme.currentTheme.pasive],
            },
          },
        },
        tooltip: {
          marker: {
            show: false,
          },
          fillSeriesColor: false,
          theme: true,
          x: {
            show: false,
            formatter: function (value) {
              return moment(value).locale("es").format("DD/MM/YYYY hh:mm:ss A");
            },
          },
        },
      },
    };
  },
  computed: {
    formattedData() {
      if (!this.data) {
        return [];
      }

      return this.data
        .sort((a, b) => {
          const dateA = new Date(`${a.date} ${a.time}`).getTime();
          const dateB = new Date(`${b.date} ${b.time}`).getTime();
          return dateA - dateB;
        })
        .map((item) => {
          const dateTimeString = `${item.date} ${item.time}`;
          const dateObject = new Date(dateTimeString);
          return {
            x: dateObject, // ApexCharts expects date objects for datetime axes
            y: parseFloat(item.total),
          };
        });
    },
  },
  methods: {
    formatMoney(x) {
      return formatCurrency(x);
    },
  },
};
</script>

<style>
.apexcharts-tooltip-yaxis {
  display: none !important;
}
</style>
